import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.main`
  background-color: #009ba8;
  width: 100%;
  padding: 30px 0;
`;

const Article = styled.article`
  background-color: #fff;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  padding: 30px;

  @media (min-width: 1600px) {
    width: 70%;
  }

  @media (max-width: 800px) {
    width: 90%;
    padding: 30px 15px;
  }

  @media (max-width: 480px) {
    width: 96%;
  }
`;

const Main = ({children}) => (
  <StyledWrapper>
    <Article>
      {children}
    </Article>
  </StyledWrapper>
)

export default Main;
