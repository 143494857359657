import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/de';
import Slider from "react-slick";

import GlobalStyles from '../components/globalStyles';
import Header from '../components/header'
import Main from '../components/main';
import LocalFilter from '../components/localFilter';
import SingleLocation from '../components/singleLocation';
import mapMark from '../images/map-mark.svg';
import infoIcon from '../images/info-icon.svg';
import clockIcon from '../images/clock-icon.svg';
import arrowBigRight from '../images/arrow-big-right.svg';
import { URL } from '../utils/utils';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Arrow
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Arrow
      prev
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-template-rows: 45px;
  grid-template-areas: 
    "filterHeader map"
    "filter map"
    "locationsHeader locationsHeader"
    "locations locations";
  gap: 30px;
  grid-row-gap: 0;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-areas: 
    "filterHeader"
    "filter"
    "locationsHeader"
    "locations"
    "map";
  }
`;

const Filter = styled.div`
  grid-area: filter;
  margin-bottom: 15px;
`;

const H3 = styled.h3`
  color: #009ba8;
  font-family: 'GaramondCond-Bold', sans-serif;
  font-size: 20px;
  letter-spacing: 0.12px;
  margin: 0;
`;

const FilterHeader = styled(H3)`
  grid-area: filterHeader;
`;

const LocationsHeader = styled(H3)`
  grid-area: locationsHeader;
  margin-bottom: 14px;
`;

const DynamicMap = styled.div`
  width: 100%;
  height: 434px;
  background-color: #aaa;
  grid-area: map;
  margin-bottom: 50px;
`;

const SliderContainer = styled(Slider)`
  margin: 0 25px;

  .slick-list {
    margin: 0 15px;
  }

  .slick-slide {
    display: flex;
    justify-content: center;

    img {
      margin: 0 6px 0 0;
    }

    > div {
      width: 98%;
    }
  }

  @media(max-width: 608px) {
    margin: 0 16px;

    .slick-list {
      margin: 0 16px;
    }
  }

  @media(max-width: 442px) {
    margin: 0;

    .slick-list {
      margin: 0;
    }

    .slick-track {
      display: flex;
      flex-flow: column;
    }
  }
`;

const Arrow = styled.div`
  background: #009ba8;
  height: 248px;
  width: 36px;

  :hover {
    background: #009ba8;
  }

  :before {
    content: url(${arrowBigRight});
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 50%;
    margin-left: -5px;
    transform: ${props => props.prev ? "rotate(180deg)" : null};
  }

  @media(max-width: 608px) {
    width: 24px;
  }

  @media(max-width: 440px) {
    display: none !important;
  }
`;

class IndexPage extends Component {
  state = {
    ready: false,
    checkedItems: new Map(),
    categoriesId: new Map(),
    categories: null,
    places: null,
    currentDay: null,
    workingHoursForParticularDay: null,
    map: null,
    markers: [],
  }

  componentDidMount() {
    this.setState({ready: true});

    const API_CATEGORIES = `${URL}/api/categories`;
    const API_PLACES = `${URL}/api/places`;
    const DAYS = ["MONTAG", "DIENSTAG", "MITTWOCH", "DONNERSTAG", "FREITAG", "SAMSTAG", "SONNTAG"];

    this.setState({currentDay: DAYS[new Date().getDay() - 1]});

    axios.get(API_CATEGORIES)
      .then(response => {
        this.setState({ categories: response.data });
        this.state.categories.map(category => {
          return this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(category.name, false),
            categoriesId: prevState.categoriesId.set(category.name, category.id)
          }));
        })
      })
      .catch(err => console.log(err));

    axios.get(API_PLACES)
      .then(response => {
        this.setState({places: response.data})
      })
      .then(() => this.openHoursForCurrentDay())
      .then(() => this.initMap(false))
      .catch(err => console.log(err));
  }

  openHoursForCurrentDay = () => {
    let workingHoursForParticularDay = {};
    this.state.places.forEach(place => {
      workingHoursForParticularDay[place.name] = '';
      place.periods.forEach(period => {
        if(period.open_day === this.state.currentDay) {
          workingHoursForParticularDay[place.name] += `
          <div>
            <p
              style="font-family: 'ProximaNova-Regular', sans-serif;
              font-size: 15px;
              letter-spacing: 0.06px;
              color: #494949;
              margin: 0 4px 0 7px;"> 
              <span
                style="width: 40px;
                display: inline-block;">
                ${moment(period.open_time).format('HH:mm')}
              </span>
               - ${moment(period.close_time).format('HH:mm')}
            </p>
          </div>`;
        }
      })
    })
    this.setState({workingHoursForParticularDay})
  }

  initMap = (updateMarkers) => {
    if(!updateMarkers) {
      var map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 47.261994, lng: 8.178476 },
        zoom: 9,
        gestureHandling: 'greedy'
      })
      this.setState({map});
    }

    if(updateMarkers) {
      this.state.markers.forEach(marker => {
        marker.setMap(null);
      })
      this.setState({markers: []})
    }

    const infowindow = new window.google.maps.InfoWindow()

    const categoriesObject = Object.fromEntries(this.state.checkedItems)
    const checkedCategoriesNames = Object.keys(categoriesObject).filter(item => categoriesObject[item])
    const checkedCategoriesIds = checkedCategoriesNames.map(category => this.state.categories.find(item => item.name === category).id)

    this.state.places.forEach(place => {
      let contentString = `<div>
        <div style="margin-bottom: 25px;">
          <div>
            <h4
              style="margin: 0 0 14px 0;
              font-family: 'ProximaNova-Bold', sans-serif;
              font-size: 15px;
              letter-spacing: 0.06px;
              color: #494949;">
              ${place.name}
            </h4>
            <p
              style="font-family: 'ProximaNova-Regular', sans-serif;
              font-size: 15px;
              letter-spacing: 0.06px;
              color: #494949;
              margin: 0;">
              ${place.street}
            </p>
            <p
              style="font-family: 'ProximaNova-Regular', sans-serif;
              font-size: 15px;
              letter-spacing: 0.06px;
              color: #494949;
              margin: 0 0 14px 0;">
              ${place.city}
            </p>
            <div
              style="display: flex;
              align-items: flex-start;
              margin-bottom: 8px;">
              <img src=${clockIcon}></img>
              <p
                style="font-family: 'ProximaNova-Regular', sans-serif;
                font-size: 15px;
                letter-spacing: 0.06px;
                color: #494949;
                margin: 0 0 0 7px;">
                ${this.state.workingHoursForParticularDay[place.name] ? 'Geöffnet' : 'Geschlossen'}
              </p>
              <div>
                ${this.state.workingHoursForParticularDay[place.name]}
              </div>
            </div>
          </div>
        </div>
        <div
          style="display: flex;">
          <a href=/location-details/?place=${place.id}&category=${checkedCategoriesIds.join(',')}
            style="width: 100%;">
            <button
              style="width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;">
              <img src=${infoIcon} alt="info" style="margin-right: 6px" />
              Details
            </button>
          </a>
        </div>
      </div>`;

      const marker = new window.google.maps.Marker({
        position: {lat: place.latitude, lng: place.longitude},
        map: this.state.map,
        icon: mapMark,
      })

      this.setState(prevState => ({
        markers: [...prevState.markers, marker]
      }))

      marker.addListener('click', function () {
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      })
    });
  }

  handleChange = event => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    let filteredItemsUrl = `${URL}/api/places?`;
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }), () => {
      this.state.checkedItems.forEach((value, key) => {
        if(value) {
          filteredItemsUrl += `category[]=${this.state.categoriesId.get(key)}&`;
        }
      });
      axios.get(filteredItemsUrl)
        .then(response => {
          this.setState({places: response.data})
        })
        .then(() => this.initMap(true))
        .catch(error => console.log(error));
    });
  }

  render() {
    let filterSection;
    let locations;
    const categoriesObject = Object.fromEntries(this.state.checkedItems)
    const checkedCategoriesNames = Object.keys(categoriesObject).filter(item => categoriesObject[item])
    const checkedCategoriesIds = checkedCategoriesNames.map(category => this.state.categories.find(item => item.name === category).id)

    if (this.state.categories) {
      filterSection = this.state.categories.map(category => (
        <LocalFilter
          key={category.id}
          image={category.pathImage}
          name={category.name}
          checked={this.state.checkedItems.get(category.name)}
          onChange={this.handleChange}
        />
      ))
    }

    if (this.state.places) {
      locations = this.state.places.map(place => {
        let openTime = [];
        let closeTime = [];
        place.periods.forEach(period => {
          if(period.open_day === this.state.currentDay) {
            openTime.push(moment(period.open_time));
            closeTime.push(moment(period.close_time));
          }
        });
        return (
            <SingleLocation
              key={place.id}
              name={place.name}
              street={place.street}
              city={place.city}
              openTime={openTime}
              closeTime={closeTime}
              image={place.pathImage}
              phone={place.phone}
              link={`/location-details/?place=${place.id}&category=${checkedCategoriesIds.join(',')}`} />
        )
      })
    }

    const settings = {
      infinite: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 3550,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 3150,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 2750,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 2350,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1950,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1550,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 442,
          settings: {
            swipe: false
          }
        },
      ]
    };

    return (
      <div style={{
        visibility: this.state.ready ? 'visible' : 'hidden'}}>
        <GlobalStyles />
        <Header />
        <Main>
          <StyledWrapper>
            <FilterHeader>Leistungen</FilterHeader>
            <Filter>{filterSection}</Filter>
            <LocationsHeader>Standorte</LocationsHeader>
            <DynamicMap id="map" />
          </StyledWrapper>
          <SliderContainer {...settings}>
            {locations}
          </SliderContainer>
          {this.state.places !== null && !this.state.places.length
            ? <LocationsHeader>Kein Standort entspricht den gewählten Kriterien</LocationsHeader>
            : null
          }
        </Main>
      </div>
    )
  }
}

export default IndexPage;
