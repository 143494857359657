import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import moment from 'moment';

import ContactButton from '../components/contactButton';
import {URL} from '../utils/utils';
import infoIcon from '../images/info-icon.svg';
import clockIcon from '../images/clock-icon.svg';

const StyledWrapper = styled.div`
  padding: 13px 16px;
  min-height: 248px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 20px;

  :hover {
    background-color: #e3e3e3;
  }

  @media(max-width: 442px) {
    margin-bottom: 14px;
  }
`;

const H4 = styled.h4`
  margin: 0 0 14px 0;
  font-family: 'ProximaNova-Bold', sans-serif;
  font-size: 15px;
  letter-spacing: 0.06px;
  color: #494949;
`

const LocationInfo = styled.div`
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 15px;
  letter-spacing: 0.06px;
  color: #494949;
  margin: 0;
  display: flex;

  div:first-child {
    margin-right: 4px;
  }
`

const Opened = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 14px;

  img {
    margin-right: 7px;
  }

  @media(max-width: 390px) {
    div:nth-child(2) {
      display: block;
    }
  }
`;

const OpenHours = styled.span`
  width: 40px;
  display: inline-block;
`;

const Img = styled.img`
  height: 100px;
  width: 100px;
`

const Buttons = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  align-items: flex-end;

  button {
    width: 100%;
    padding: 11px 0;
  }
`;

const DetailsButton = styled.button`
  display: flex;
  justify-content: center;
`;

class SingleLocation extends Component {  
  render() {
    let isOpen, openHours;
    if(this.props.openTime.length) {
      isOpen = 'Geöffnet'
      openHours = this.props.openTime.map((hour, index) => (
        <div key={index}>
          <OpenHours>{moment(hour).format('HH:mm')}</OpenHours> - {moment(this.props.closeTime[index]).format('HH:mm')}
        </div>
      ))
    } else {
      isOpen = 'Geschlossen'
    }

    return (
      <StyledWrapper>
        <div>
          <H4>{this.props.name}</H4>
          <LocationInfo>{this.props.street}</LocationInfo>
          <LocationInfo>{this.props.city}</LocationInfo>
          <Opened>
            <img src={clockIcon} alt="clock" />
            <LocationInfo>
              <div>{isOpen} </div>
              <div>{openHours}</div>
            </LocationInfo>
          </Opened>
	  <br />
	  <LocationInfo>{this.props.phone}</LocationInfo>
        </div>
        <div>
          <Img src={`${URL}${this.props.image}`} />
        </div>
        <Buttons>
          <Link
            to={this.props.link}>
            <DetailsButton>
              <img src={infoIcon} alt="info" />
              Details
            </DetailsButton>
          </Link>
          <ContactButton
            phone={this.props.phone}>
            Kontaktieren
          </ContactButton>
        </Buttons>
      </StyledWrapper>
    )
  }
}

export default SingleLocation;
